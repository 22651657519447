<template>
  <div class="container">
    <section :id="slug" class="page" v-if="page" v-html="page.content.rendered" />
  </div>
</template>

<script>
import axios from "axios";
// Librairie pour les tooltips éventuellement inclus dans la page
import { createPopper } from "@popperjs/core";

export default {
  name: "Page",
  props: {
    slug: String,
  },
  async created() {
    const url = this.backendURL + "/wp-json/wp/v2/pages?slug=" + this.slug;

    const response = await axios.get(url);
    this.page = response.data[0];
    this.$store.commit("setTitle", { title: this.page.title.rendered });
  },
  updated() {
    if (this.page) {
      
      // Fait marcher les anchor links (ne marchent pas de base puisque le contenu HTML de la page n'est pas chargé immédiatement)
      if (this.slug == "business" && !this.hasJumpedToAnchor) {
        let anchorMatch = window.location.href.match(/\/business#(.*?)$/);
        let anchorElt = anchorMatch && anchorMatch[1] && document.getElementById(anchorMatch[1] );
        if (anchorElt) {
          this.$nextTick(() => anchorElt.scrollIntoView({behavior: "smooth"}));
        }
        this.hasJumpedToAnchor = true;
      }

      // On initialise les tooltips dans updated pour être sûr que les données de page sont chargées
      // Inspiré du tuto Popper : https://popper.js.org/docs/v2/tutorial/
      const tooltipTargetArray = this.$el.querySelectorAll(".tooltip_target");
      let i = 0;

      for (const tooltipTarget of tooltipTargetArray) {
        tooltipTarget.dataset.tooltipId = i;
        const tooltip = tooltipTarget.nextElementSibling;

        const popperInstance = createPopper(tooltipTarget, tooltip, {
          placement: "top",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ],
        });

        const showEvents = ["mouseenter", "focus"];
        const hideEvents = ["mouseleave", "blur"];

        showEvents.forEach((event) => {
          tooltipTarget.addEventListener(event, this.showTooltip);
        });

        hideEvents.forEach((event) => {
          tooltipTarget.addEventListener(event, this.hideTooltip);
        });

        this.tooltipArray.push({
          tooltipTarget: tooltipTarget,
          tooltip: tooltip,
          popperInstance: popperInstance,
        });

        i++;
      }
    }
  },
  data() {
    return {
      page: null,
      tooltipArray: [], // Chaque élément du tableau contient : target, texte tooltip, instance Popper
      hasJumpedToAnchor: false,
    };
  },
  methods: {
    showTooltip(evt) {
      const tooltip = evt.currentTarget.nextElementSibling;
      const popperInstance = this.tooltipArray[evt.currentTarget.dataset.tooltipId].popperInstance;

      // Make the tooltip visible
      tooltip.setAttribute("data-show", "");

      // Enable the event listeners
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: "eventListeners", enabled: true }],
      }));

      // Update its position
      popperInstance.update();
    },
    hideTooltip(evt) {
      const tooltip = evt.currentTarget.nextElementSibling;
      const popperInstance = this.tooltipArray[evt.currentTarget.dataset.tooltipId].popperInstance;

      // Hide the tooltip
      tooltip.removeAttribute("data-show");

      // Disable the event listeners
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: "eventListeners", enabled: false }],
      }));
    },
  },
};
</script>


<style lang="scss">
.page {
  ul {
    //padding: 0 7rem;
    width: 75%;
    margin: 0 auto;
    text-align: left;

    li {
      margin-top: 1em;
    }

    p {
      margin: 0;
    }
  }

  h2 {
    margin-top: 2em;
    scroll-margin-top: 2em;
  }

  .tooltip_target {
    border-bottom: 1px dashed #000;
  }

  .tooltip {
    background: #9fd7ee;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    opacity: 0;
    max-width: 300px;
    text-align: center;
    transition: 1s opacity;

    .arrow,
    .arrow::before {
      position: absolute;
      // C'est la border qui fait la flèche : https://blog.logrocket.com/creating-beautiful-tooltips-with-only-css/
      border: 10px solid #000;
      border-color: #9fd7ee transparent transparent transparent;
    }

    .arrow {
      visibility: hidden;
    }

    .arrow::before {
      visibility: visible;
      content: "";
      transform: translateX(-10px); // Pour centrage horizontal
    }
  }

  .tooltip[data-show] {
    opacity: 1;
  }

  .tooltip[data-popper-placement^="top"] > .arrow {
    bottom: -9px;
  }

  @media (max-width: 992px) {
    ul {
      //padding: 0 7rem;
      width: 100%;
    }

    figure {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>